@font-face {
  font-family: "Ubuntu-Bold";
  src: url("/assets/fonts/Ubuntu-Bold.ttf") format("opentype");
}

@font-face {
  font-family: "Ubuntu-Light";
  src: url("/assets/fonts/Ubuntu-Light.ttf") format("opentype");
}

@font-face {
  font-family: "Ubuntu-Italic";
  src: url("/assets/fonts/Ubuntu-Italic.ttf") format("opentype");
}

@font-face {
  font-family: "Ubuntu-Regular";
  src: url("/assets/fonts/Ubuntu-Regular.ttf") format("opentype");
}

@font-face {
  font-family: "Ubuntu-Medium";
  src: url("/assets/fonts/Ubuntu-Medium.ttf") format("opentype");
}
